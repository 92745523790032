import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

import "./404.scss"

export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404" index={false} follow={false} />
      <div className="container404">
        <h1 className="heading">Page inexistante</h1>
        <Link to="/" className="legal-link_style">
          Retour
        </Link>
      </div>
    </Layout>
  )
}
